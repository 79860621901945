///////////////////////////////
// Timers-related functionality
///////////////////////////////

qcodo.extend( qcodo, {
	_objTimers: {},

	clearTimeout: function(strTimerId) {
		if (qcodo._objTimers[strTimerId]) {
			clearTimeout(qcodo._objTimers[strTimerId]);
			qcodo._objTimers[strTimerId] = null;
		}
	},

	setTimeout: function(strTimerId, strAction, intDelay) {
		qcodo.clearTimeout(strTimerId);
		qcodo._objTimers[strTimerId] = setTimeout(strAction, intDelay);
	}
});

/////////////////////////////////////
// Event Object-related functionality
/////////////////////////////////////
qcodo.extend( qcodo, {
	handleEvent: function(objEvent) {
		objEvent = (objEvent) ? objEvent : ((typeof(event) == "object") ? event : null);

		if (objEvent) {
			if (typeof(objEvent.clientX) != "undefined") {
				qcodo.mouse.clientX = objEvent.clientX;
				qcodo.mouse.clientY = objEvent.clientY;
			}

			if ( !objEvent.which && objEvent.button !== undefined )
				objEvent.which = (objEvent.button & 1 ? 1 : ( objEvent.button & 2 ? 3 : ( objEvent.button & 4 ? 2 : 0 ) ));

			qcodo.mouse.left = (objEvent.which == 1);
			qcodo.mouse.right = (objEvent.which == 3);
			qcodo.mouse.middle = (objEvent.which == 2);

			if ( !objEvent.target )
				objEvent.target = objEvent.srcElement || document;
			if ( objEvent.target.nodeType === 3 )
				objEvent.target = objEvent.target.parentNode;

			var objDimensions = qcodo.getClientPosition();
			qcodo.client.x = objDimensions.x;
			qcodo.client.y = objDimensions.y;

			objDimensions = qcodo.getClientSize();
			qcodo.client.width = objDimensions.width;
			qcodo.client.height = objDimensions.height;

			objDimensions = qcodo.getPageSize();
			qcodo.page.width = objDimensions.width;
			qcodo.page.height = objDimensions.height;

			// These Values are "By Definition"
			qcodo.mouse.pageX = qcodo.mouse.clientX + qcodo.client.x;
			qcodo.mouse.pageY = qcodo.mouse.clientY + qcodo.client.y;
		};
		
		//qcodo.logDimensionsOverlay();
		return objEvent;
	},

	terminateEvent: function(objEvent) {
		objEvent = qcodo.handleEvent(objEvent);

		if (objEvent) {
			// Stop Propogation
			if (objEvent.preventDefault)
				objEvent.preventDefault();
			if (objEvent.stopPropagation)
				objEvent.stopPropagation();
			objEvent.cancelBubble = true;
			objEvent.returnValue = false;
		}

		return false;
	},

///////////////////////////////
// Event Stats-Releated Objects
///////////////////////////////

	//mouse object holds position of the mouse relative to client viewport (visible portion of the page) and whole page
	mouse: {
		clientX: 0,
		clientY: 0,
		pageX: 0,
		pageY: 0,
		left: false,
		middle: false,
		right: false
	},

	//this object holds position (relative to whole page) and size of client viewport 
	client: {
		x: null,
		y: null,
		width: null,
		height: null
	},

	//this object holds size of whole page
	page: {
		x: 0,
		y: 0,
		width: null,
		height: null
	},

	//these function update qcodo.client and qcodo.page objects
	getClientSize: function() {
		var wd = window.document;
		return { 
			width: !qcodo.compatMode && wd.documentElement.clientWidth  || wd.body.clientWidth,
			height: !qcodo.compatMode && wd.documentElement.clientHeight || wd.body.clientHeight
		};
	},

	getPageSize: function() {
		var d = document;
		return {
			width: Math.max(d.documentElement.clientWidth, d.body.scrollWidth, d.documentElement.scrollWidth, d.body.offsetWidth, d.documentElement.offsetWidth),
			height: Math.max(d.documentElement.clientHeight, d.body.scrollHeight, d.documentElement.scrollHeight, d.body.offsetHeight, d.documentElement.offsetHeight)
		};
	},
	
	getClientPosition: function() {
		var w = window;
		return {
			x: w ? ("pageXOffset" in w) ? w.pageXOffset : qcodo.boxModel() && w.document.documentElement.scrollLeft || w.document.body.scrollLeft : w.scrollLeft,
			y: w ? ("pageYOffset" in w) ? w.pageYOffset : qcodo.boxModel() && w.document.documentElement.scrollTop || w.document.body.scrollTop : w.scrollTop
		};
	}
});