// PostBack and AjaxPostBack

qcodo.extend(qcodo, {
	updateCkeditor: function() {
		if (typeof window.CKEDITOR != 'undefined') {
			for(var i in window.CKEDITOR.instances) {
                window.CKEDITOR.instances[i].updateElement();
			}
		}
	},

	postBack: function(strForm, strControl, strEvent, strParameter) {
		var f = qcodo.objForm;
		f.Qform__FormControl.value = strControl;
		f.Qform__FormEvent.value = strEvent;
		f.Qform__FormParameter.value = strParameter;
		f.Qform__FormCallType.value = "Server";
		f.Qform__FormUpdates.value = qcodo.formUpdates();
		f.Qform__FormCheckableControls.value = qcodo.formCheckableControls(strForm, "Server");
		f.submit();
	},

	formUpdates: function() {
		qcodo.updateCkeditor();
		
		var strToReturn = "";
		for (var strControlId in qcodo.controlModifications) {
			if (qcodo.controlModifications.hasOwnProperty(strControlId)) {
				for (var strProperty in qcodo.controlModifications[strControlId]) {
					if (qcodo.controlModifications[strControlId].hasOwnProperty(strProperty)) {
						strToReturn += strControlId + " " + strProperty + " " + qcodo.controlModifications[strControlId][strProperty] + "\n";
					}
				}
			}
		}
		qcodo.controlModifications = {};
		return strToReturn;
	},

	formCheckableControls: function(strForm, strCallType) {
		var objForm = document.getElementById(strForm);
		var strToReturn = "";

		for (var intIndex = 0; intIndex < objForm.elements.length; intIndex++) {
			if (
				(objForm.elements[intIndex].type == "checkbox" || objForm.elements[intIndex].type == "radio") &&
				(strCallType == "Ajax" || !objForm.elements[intIndex].disabled)
			) {
				// CheckBoxList
				if (objForm.elements[intIndex].id.indexOf('[') >= 0) {
					if (objForm.elements[intIndex].id.indexOf('[0]') >= 0) {
						strToReturn += " " + objForm.elements[intIndex].id.substring(0, objForm.elements[intIndex].id.length - 3);
					}
				// RadioButtonList
				} else if (objForm.elements[intIndex].id.indexOf('_') >= 0) {
					if (objForm.elements[intIndex].id.indexOf('_0') >= 0) {
						strToReturn += " " + objForm.elements[intIndex].id.substring(0, objForm.elements[intIndex].id.length - 2);
					}
				// Standard Radio or Checkbox
				} else {
					strToReturn += " " + objForm.elements[intIndex].id;
				}
			}
		}

		return (strToReturn.length > 0) ? strToReturn.substring(1) : "";
	},

	ajaxQueue: [],

	postAjax: function(strForm, strControl, strEvent, strParameter, strWaitIconControlId) {
		if (!qcodo.unloadFlag) { // Only add if we're not unloaded
			if (qcodo.beforeUnloadFlag) {
                qcodo.beforeUnloadFlag = false;
            }

			var blnQueueEmpty = false;
			if (qcodo.ajaxQueue.length == 0) { // Figure out if Queue is Empty
                blnQueueEmpty = true;
            }

			// Enqueue the AJAX Request
            qcodo.ajaxQueue.push([strForm, strControl, strEvent, strParameter, strWaitIconControlId]);

			if (blnQueueEmpty) { // If the Queue was originally empty, call the Dequeue
                qcodo.dequeueAjaxQueue();
            }
		}
	},
	
	clearAjaxQueue: function() {
        qcodo.ajaxQueue = [];
	},

	objAjaxWaitIcon: null,
	ajaxRequest: null,

    openErrorWindow: function(strText) {
	    var objErrorWindow = document.createElement('div');
	    objErrorWindow.setAttribute("style", "position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 999999; background-color: rgba(0, 0, 0, 0.8);");

	    var content = document.createElement('div');
	    content.setAttribute("style", "position: relative; margin: 0 auto; width: 1000px; z-index: 1000000;");

	    var iframe = document.createElement('iframe');
	    iframe.setAttribute('width', 1000);
	    iframe.setAttribute('height', 750);
	    iframe.setAttribute('frameborder', 0);
	    iframe.setAttribute('style', 'background-color: #ffffff; margin-top: 40px');

	    content.appendChild(iframe);
	    objErrorWindow.appendChild(content);
	    window.document.body.appendChild(objErrorWindow);

	    iframe.contentWindow.document.open();
	    iframe.contentWindow.document.write(strText);
	    iframe.contentWindow.document.close();
    },

	handleAjaxResponse: function(event) {
		if (qcodo.ajaxRequest.readyState == 4) {
			var objRequest = qcodo.ajaxRequest;

			if (!qcodo.beforeUnloadFlag) {
				try {
					var objXmlDoc = objRequest.responseXML;
					if (!objXmlDoc) {
						//alert("An error occurred during AJAX Response parsing.\r\n\r\nThe error response will appear in a new popup.");
                        qcodo.openErrorWindow(objRequest.responseText);
						return;
					} else {
						// Go through Controls
						var objXmlControls = objXmlDoc.getElementsByTagName('control');

                        for (var i = 0; i < objXmlControls.length; i++) {
							var strControlId = objXmlControls[i].attributes.getNamedItem('id').nodeValue;

							var strControlHtml = "";
							if (objXmlControls[i].textContent)
								strControlHtml = objXmlControls[i].textContent;
							else if (objXmlControls[i].firstChild)
								strControlHtml = objXmlControls[i].firstChild.nodeValue;

							// Perform Callback Responsibility
							if (strControlId == "Qform__FormState") {
								var objFormState = document.getElementById(strControlId);
								objFormState.value = strControlHtml;							
							} else {
								var objSpan = document.getElementById(strControlId + "_ctl");
								if (objSpan) {
                                    objSpan.innerHTML = strControlHtml;
                                }
							}
						}

						// Go through Commands
						var objXmlCommands = objXmlDoc.getElementsByTagName('command');

						for (i = 0; i < objXmlCommands.length; i++) {
							if (objXmlCommands[i] && objXmlCommands[i].firstChild) {
								var strCommand = "";
								var intChildLength = objXmlCommands[i].childNodes.length;
								for (var intChildIndex = 0; intChildIndex < intChildLength; intChildIndex++)
									strCommand += objXmlCommands[i].childNodes[intChildIndex].nodeValue;
								eval(strCommand);
							}
						}
					}
				} catch (objExc) {
					alert("An error occurred during AJAX Response handling.\r\n\r\nThe error response will appear in a new popup.\r\n\r\n" + objExc.message + "\r\non line number " + objExc.lineNumber + "\r\nin file " + objExc.fileName);
                    qcodo.openErrorWindow(objRequest.responseText);
					return;
				}
			}

			// Perform the Dequeue
            qcodo.ajaxQueue.shift();

			// Hid the Cog\Forms\WaitIcon (if applicable)
			if (qcodo.objAjaxWaitIcon) {
                qcodo.objAjaxWaitIcon.style.display = 'none';
            }

			// If there are still AjaxEvents in the queue, go ahead and process/dequeue them
			if (qcodo.ajaxQueue.length > 0) {
                qcodo.dequeueAjaxQueue();
            }
		}
	},

	dequeueAjaxQueue: function() {
		if (qcodo.ajaxQueue.length > 0) {
			var strForm = qcodo.ajaxQueue[0][0];
            var strControl = qcodo.ajaxQueue[0][1];
            var strEvent = qcodo.ajaxQueue[0][2];
            var strParameter = qcodo.ajaxQueue[0][3];
            var strWaitIconControlId = qcodo.ajaxQueue[0][4];

			// Display WaitIcon (if applicable)
			if (strWaitIconControlId) {
                qcodo.objAjaxWaitIcon = qcodo.getWrapper(strWaitIconControlId);
				if (qcodo.objAjaxWaitIcon) {
					qcodo.objAjaxWaitIcon.style.display = 'inline';
				}
			}

			var f = qcodo.objForm;
			f.Qform__FormControl.value = strControl;
			f.Qform__FormEvent.value = strEvent;
			f.Qform__FormParameter.value = strParameter;
			f.Qform__FormCallType.value = "Ajax";
			f.Qform__FormUpdates.value = qcodo.formUpdates();
			f.Qform__FormCheckableControls.value = qcodo.formCheckableControls(strForm, "Ajax");

			var strPostData = "";
			for (var i = 0; i < f.elements.length; i++) {
				switch (f.elements[i].type) {
					case "checkbox":
					case "radio":
						if (f.elements[i].checked) {
							var strTestName = f.elements[i].name + "_";
							if (f.elements[i].id.substring(0, strTestName.length) == strTestName) {
								strPostData += "&" + f.elements[i].name + "=" + f.elements[i].id.substring(strTestName.length);
							} else {
								strPostData += "&" + f.elements[i].id + "=" + f.elements[i].value;
							}
						}
						break;

					case "select-multiple":
						for (var intIndex = 0; intIndex < f.elements[i].options.length; intIndex++)
							if (f.elements[i].options[intIndex].selected) {
								strPostData += "&" + f.elements[i].name + "=";
								strPostData += f.elements[i].options[intIndex].value;
							}
						break;

					default:
						strPostData += "&" + f.elements[i].id + "=";

						// For Internationalization -- we must escape the element's value properly
						var strPostValue = f.elements[i].value;
						if (strPostValue) {
							strPostValue = strPostValue.replace(/\%/g, "%25");
							strPostValue = strPostValue.replace(/&/g, encodeURIComponent('&'));
							strPostValue = strPostValue.replace(/\+/g, "%2B");
						}
						strPostData += strPostValue;
						break;
				}
			}

			var strUri = f.action;
            var objRequest = new XMLHttpRequest();
			if (objRequest) {
				objRequest.open("POST", strUri, true);
				objRequest.setRequestHeader("Method", "POST " + strUri + " HTTP/1.1");
				objRequest.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

				objRequest.onreadystatechange = qcodo.handleAjaxResponse;
				qcodo.ajaxRequest = objRequest;
				objRequest.send(strPostData);
			}
		}
	}
});

// Qcodo Shortcuts
qc.pB = qcodo.postBack;
qc.pA = qcodo.postAjax;