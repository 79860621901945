/////////////////////////////////
// Controls-related functionality
/////////////////////////////////

qcodo.extend(qcodo, {
	getControl: function(mixControl) {
		if (typeof(mixControl) == 'string')
			return document.getElementById(mixControl);

		return mixControl;
	},

	getWrapper: function(mixControl) {
		var objControl = qcodo.getControl(mixControl);
		if (objControl)
			return qcodo.getControl(objControl.id + '_ctl');

		return null;
	}
});

// Functions for objWrapper
qcodo.objWrapperExtender = {

	// Updating Style-related Things
	updateStyle: function(strStyleName, strNewValue) {
		var objControl = this.control;
		var recordControlMod = qcodo.recordControlModification;

		switch (strStyleName) {
			case "className":
				objControl.className = strNewValue;
				recordControlMod(objControl.id, "CssClass", strNewValue);
				break;

			case "parent":
				if (strNewValue) {
					var objNewParentControl = qcodo.getControl(strNewValue);
					objNewParentControl.appendChild(this);
					recordControlMod(objControl.id, "Parent", strNewValue);
				} else {
					var objParentControl = this.parentNode;
					objParentControl.removeChild(this);
					recordControlMod(objControl.id, "Parent", "");
				}
				break;

			case "displayStyle":
				objControl.style.display = strNewValue;
				recordControlMod(objControl.id, "DisplayStyle", strNewValue);
				break;

			case "display":
				if (strNewValue) {
					this.style.display = "inline";
					recordControlMod(objControl.id, "Display", "1");
				} else {
					this.style.display = "none";
					recordControlMod(objControl.id, "Display", "0");
				}
				break;

			case "enabled":
				if (strNewValue) {
					this.control.removeAttribute('disabled');
					recordControlMod(objControl.id, "Enabled", "1");
				} else {
					this.control.setAttribute('disabled', 'disabled');
					recordControlMod(objControl.id, "Enabled", "0");
				}
				break;

			case "width":
			case "height":
				objControl.style[strStyleName] = strNewValue;
				if (qcodo.javascriptStyleToQcodo[strStyleName])
					recordControlMod(objControl.id, qcodo.javascriptStyleToQcodo[strStyleName], strNewValue);
				if (this.handle)
					this.updateHandle();
				break;

			case "text":
				objControl.innerHTML = strNewValue;
				recordControlMod(objControl.id, "Text", strNewValue);
				break;

			default:
				if (qcodo.javascriptWrapperStyleToQcodo[strStyleName]) {
					this.style[strStyleName] = strNewValue;
					recordControlMod(objControl.id, qcodo.javascriptWrapperStyleToQcodo[strStyleName], strNewValue);
				} else {
					objControl.style[strStyleName] = strNewValue;
					if (qcodo.javascriptStyleToQcodo[strStyleName])
						recordControlMod(objControl.id, qcodo.javascriptStyleToQcodo[strStyleName], strNewValue);
				}
				break;
		}
	},

	// Positioning-related functions
	getAbsolutePosition: function() {
		var intOffsetLeft = 0;
		var intOffsetTop = 0;

		var objControl = this.control;

		while (objControl) {
			// If we are IE, we don't want to include calculating controls who's wrappers are position:relative
			if ((objControl.wrapper) && (objControl.wrapper.style.position == "relative")) {}
			else {
				intOffsetLeft += objControl.offsetLeft;
				intOffsetTop += objControl.offsetTop;
			}
			objControl = objControl.offsetParent;
		}

		return { x: intOffsetLeft, y: intOffsetTop };
	},

	setAbsolutePosition: function(intNewX, intNewY, blnBindToParent) {
		var objControl = this.offsetParent;

		while (objControl) {
			intNewX -= objControl.offsetLeft;
			intNewY -= objControl.offsetTop;
			objControl = objControl.offsetParent;
		}

		if (blnBindToParent) {
			if (this.parentNode.nodeName.toLowerCase() != 'form') {
				// intNewX and intNewY must be within the parent's control
				intNewX = Math.max(intNewX, 0);
				intNewY = Math.max(intNewY, 0);

				intNewX = Math.min(intNewX, this.offsetParent.offsetWidth - this.offsetWidth);
				intNewY = Math.min(intNewY, this.offsetParent.offsetHeight - this.offsetHeight);
			}
		}

		qcodo.updateStyle("left", intNewX + "px");
		qcodo.updateStyle("top", intNewY + "px");
	},

	// Toggle Display / Enabled
	toggleDisplay: function(strShowOrHide, intDuration) {
		// Toggles the display/hiding of the entire control (including any design/wrapper HTML)
		// If ShowOrHide is blank, then we toggle
		// Otherwise, we'll execute a "show" or a "hide"

		intDuration = intDuration || 500; //default duration

		var show = function() {
			this.updateStyle("display", true);
			qcodo.animate(this, 'opacity: 1', { duration: intDuration });
		};

		var hide = function() {
			qcodo.animate(this, 'opacity: 0', { duration: intDuration, after: function() { this.updateStyle("display", false); } });
		};

		if (strShowOrHide) {
			if (strShowOrHide == "show")
				show();
			else
				hide();
		} else {
			if (this.style.display == "none")
				show();
			else
				hide();
		}
	},

	toggleEnabled: function(strEnableOrDisable) {
		if (strEnableOrDisable) {
			if (strEnableOrDisable == "enable")
				qcodo.updateStyle("enabled", true);
			else
				qcodo.updateStyle("enabled", false);
		} else
			qcodo.updateStyle("enabled", (this.control.disabled) ? true : false);
	},

	// Focus
	focus: function() {
		if ( this.control !== qcodo.safeActiveElement() && this.control.focus ) {
			this.control.focus();
		}
		return false;
	},

	// Blur
	blur: function () {
		if (this.control === qcodo.safeActiveElement() && this.control.blur) {
			this.control.blur();
		}
		return false;
	},

	// Select All (will only work for textboxes only)
	select: function() {
		if (this.control.select)
			this.control.select();
	},

	// Blink
	blink: function(strToColor, intDuration) {
		intDuration = intDuration || 800; //default duration

		var objControl = this.control;
		objControl.originalBackgroundColor = qcodo.computedStyle(objControl).backgroundColor;

		qcodo.animate(objControl, 'background-color:' + strToColor, {
			duration: intDuration / 2,
			after: function() {
				qcodo.animate(objControl, 'background-color:' + objControl.originalBackgroundColor, { duration: intDuration / 2 });
			}
		});
	}
};


/////////////////////////////
// Register Control - General
/////////////////////////////

qcodo.extend(qcodo, {
	controlModifications: {},
	javascriptStyleToQcodo: {
		backgroundColor: "BackColor",
		borderColor: "BorderColor",
		borderStyle: "BorderStyle",
		border: "BorderWidth",
		height: "Height",
		width: "Width",
		text: "Text"
	},

	javascriptWrapperStyleToQcodo: {
		position: "Position",
		top: "Top",
		left: "Left"
	},

	recordControlMod: function(strControlId, strProperty, strNewValue) {
        var controlMod = qcodo.controlModifications;
		if (!controlMod[strControlId])
            controlMod[strControlId] = {};
        controlMod[strControlId][strProperty] = strNewValue;
	},
	
	registerControl: function(mixControl) {
		var objControl = qcodo.getControl(mixControl);
		if (!objControl)
			return;

		// Link the Wrapper and the Control together
		var objWrapper = qcodo.getWrapper(objControl);
		objControl.wrapper = objWrapper;
		objWrapper.control = objControl;

		// Create New Methods, etc.
		// Like: objWrapper.something = xyz;
        qcodo.extend(objWrapper, qcodo.objWrapperExtender);
	},

	registerControlArray: function(mixControlArray) {
        qcodo.each(mixControlArray, function(i, el) {
            qcodo.registerControl(el);
        });
	}
});

//////////////////
// Qcodo Shortcuts
//////////////////

qc.getC = qcodo.getControl;
qc.getW = qcodo.getWrapper;
qc.regC = qcodo.registerControl;
qc.regCA = qcodo.registerControlArray;
