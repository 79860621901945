////////////////////////////////
// Browser-related functionality
////////////////////////////////

(function(documentReady, container) {
	var wd = window.document,
		isReady = false,
		eventBound = false,
		readyList = [];

	function ready() {
		if (!isReady) {
			if (!wd.body) return setTimeout(ready, 13);
			isReady = true;

			while (listener = readyList.shift())
				listener();

			readyList = null;
		}
	}

	function DOMLoaded() {
		wd.removeEventListener("DOMContentLoaded", DOMLoaded, false);
		ready();
	}

	function bindReady() {
		if (eventBound) return;
		eventBound = true;
		if (wd.readyState === "complete") return ready();

		wd.addEventListener("DOMContentLoaded", DOMLoaded, false);
		window.addEventListener("load", ready, false);
	}

	container[documentReady] = function(fn) {
		bindReady();
		if (isReady)
			fn.call(wd);
		else
			readyList.push(fn);
	};

})('documentReady', qcodo);

qcodo.extend( qcodo, {
    browser: (function() {
        var ua = navigator.userAgent.toLowerCase();
        var browser = { webkit: false, opera: false, ie: false, mozilla: false };

        var match = /(webkit)[ \/]([\w.]+)/.exec(ua) ||
            /(opera)(?:.*version)?[ \/]([\w.]+)/.exec(ua) ||
            /(msie) ([\w.]+)/.exec(ua) ||
            !/compatible/.test(ua) && /(mozilla)(?:.*? rv:([\w.]+))?/.exec(ua) ||
            [];
        if (match[1] == 'msie') match[1] = 'ie';
        if (match[1]) browser[match[1]] = true;

        browser.version = match[2] || "0";

        browser.platform = {
            mac: /mac/.test(ua),
            win: /win/.test(ua),
            linux: /linux/.test(ua),
            android: /android \d\.\d/.test(ua),
            iphoneos: /apple.*mobile.*safari/.test(ua)
        };

        return browser;
    })(),

    loadJavaScriptFile: function(strScript, objCallback) {
        if (strScript.substring(0, 7) != 'http://' && strScript.substring(0, 8) != 'https://') {
            strScript = qcodo.jsAssets + "/" + strScript;
        }
        var el = document.createElement("script");
        el.setAttribute("src", strScript);
        qcodo.objForm.appendChild(el);

        el.onreadystatechange = objCallback;
        el.onload = objCallback;
    },

    loadStyleSheetFile: function(strStyleSheetFile, strMediaType) {
        var el = document.createElement('link');
        el.rel = 'stylesheet';
        el.type = 'text/css';
        el.href = qcodo.cssAssets + "/" + strStyleSheetFile;
        el.media = strMediaType;

        if (typeof el != 'undefined') {
            document.getElementsByTagName("head")[0].appendChild(el);
        }
    },

    safeActiveElement: function() {
        try { return document.activeElement; }
        catch (err) {}
    },

    computedStyle: function(elem) {
        if (elem.currentStyle)
            return elem.currentStyle;
        else
            return window.getComputedStyle(elem, null);
    },

    setOpacity: function(elem, intOpacity) {
        var reOpacity = /alpha\s*\(\s*opacity\s*=\s*([^\)]+)\)/;

        if (typeof elem.style.opacity == 'string')
            elem.style.opacity = intOpacity;
        else {
            var es = elem.style;
            es.zoom = 1;
            if (reOpacity.test(es.filter)) {
                intOpacity = intOpacity >= 0.9999 ? '' : ('alpha(opacity=' + (intOpacity * 100) + ')');
                es.filter = es.filter.replace(reOpacity, intOpacity);
            }
            else
                es.filter += ' alpha(opacity=' + (intOpacity * 100) + ')';
        }
    },

    // CSS1Compat - standards mode (false), BackCompat - quirks mode (true)
    compatMode: (function() {
        return window.document.compatMode === "BackCompat";
    })()
});

qcodo.documentReady( function() {
	qcodo.boxModel = (function() {
		var boxModel, div = document.createElement("div");

		div.style.width = div.style.paddingLeft = "1px";
		document.body.appendChild(div);

		boxModel = div.offsetWidth === 2;
		document.body.removeChild(div).style.display = 'none';
		return boxModel;
	})();
});